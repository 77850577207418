import React, { Component } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default class Privacy extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO
          title="Legal Notice | Miquel Trallero | Fullstack Blockchain Developer"
        />
        <div className="site-container ">
          <div className="container">        
            <h1>LEGAL NOTICE AND TERMS OF USE</h1>

            <h2>I. GENERAL INFORMATION</h2>

            <p>In compliance with the duty of information provided in Law 34/2002 of Services of the Information Society and Electronic Commerce (LSSI-CE) of July 11, the following general information about this website is provided below:</p>

            <p>The ownership of this website, https://www.miqueltrallero.com, (hereinafter, Website) is held by: Miquel Trallero Campins, with NIF: 46151089-W, and whose contact details are:</p>
        
            <ul>
              <li>Address: Travessera De Les Corts, 194, Barcelona, Spain</li>
              <li>Contact telephone number: 618721200</li>
              <li>Contact email: miqueltrallero.c@gmail.com</li>
            </ul>

            <h2>II. GENERAL TERMS AND CONDITIONS OF USE</h2>

            <h3>The object of the conditions: The Web Site</h3>

            <p>The purpose of these General Conditions of Use (hereinafter, Conditions) is to regulate access to and use of the Website. For the purposes of these Terms and Conditions, the Website shall be understood as: the external appearance of the screen interfaces, both statically and dynamically, i.e. the navigation tree; and all the elements integrated both in the screen interfaces and in the navigation tree (hereinafter, Contents) and all those online services or resources offered to Users (hereinafter, Services).</p>
            <p>Miquel Trallero reserves the right to modify, at any time and without prior notice, the presentation and configuration of the Web Site and the Contents and Services that may be incorporated therein. The User acknowledges and accepts that at any time Miquel Trallero may interrupt, deactivate and/or cancel any of these elements that are integrated into the Website or access to them.</p>
            <p>Access to the Web Site by the User is free and, as a general rule, is free without the User having to provide a consideration to enjoy it, except for the cost of connection through the telecommunications network provided by the access provider that the User has contracted.</p>
            <p>The use of the Contents does not require prior subscription or registration.</p>

            <h3>The user</h3>

            <p>The access, navigation and use of the Website, as well as the spaces enabled to interact between Users, and the User and Miquel Trallero, such as comments and / or blogging spaces, confers the condition of User, so they accept, from the moment they start browsing the Website, all the Conditions set forth herein, as well as its subsequent modifications, without prejudice to the application of the corresponding legal regulations of mandatory compliance as the case may be. Given the relevance of the above, the User is recommended to read them every time he/she visits the Website.</p>
            <p>Miquel Trallero's Website provides a great diversity of information, services and data. The User assumes responsibility for the correct use of the Website. This responsibility will extend to:</p>
            <p>A use of the information, Contents and/or Services and data offered by Miquel Trallero without being contrary to the provisions of these Conditions, the Law, morality or public order, or that in any other way may involve injury to the rights of third parties or the same operation of the Website.</p>
            <p>The veracity and legality of the information provided by the User in the forms provided by Miquel Trallero for access to certain Content or Services offered by the Website. In any case, the User shall immediately notify Miquel Trallero of any event that allows the improper use of the information registered in said forms, such as, but not limited to, theft, loss, or unauthorized access to identifiers and/or passwords, in order to proceed to their immediate cancellation.</p>
            <p>In any case, Miquel Trallero will not be responsible for the opinions expressed by Users through comments or other blogging or participation tools that may exist.</p>
            <p>The mere access to this Website does not imply any type of commercial relationship between Miquel Trallero and the User.</p>
            <p>Always in compliance with current legislation, this Miquel Trallero Web Site is aimed at all persons, regardless of their age, who may access and/or browse the pages of the Web Site.</p>

            <h2>III. ACCESS AND NAVIGATION ON THE WEB SITE: EXCLUSION OF WARRANTIES AND LIABILITY</h2>

            <p>Miquel Trallero does not guarantee the continuity, availability and usefulness of the Web Site, nor of the Contents or Services. Miquel Trallero will do everything possible for the proper functioning of the Web Site, however, is not responsible or guarantee that access to this Web Site will not be uninterrupted or error free.</p>
            <p>Miquel Trallero is not responsible or guarantee that the content or software that can be accessed through this Web Site, is free of error or cause damage to the computer system (software and hardware) of the User. In no event shall Miquel Trallero be liable for any loss or damage of any kind arising from access, navigation and use of the Web Site, including, but not limited to, those caused to computer systems or those caused by the introduction of viruses.</p>
            <p>Miquel Trallero is also not responsible for any damage that may be caused to users by improper use of this Web Site. In particular, it is not responsible in any way for the falls, interruptions, lack or defect of telecommunications that may occur.</p>

            <h2>IV. LINK POLICY</h2>

            <p>It is reported that the Miquel Trallero Web Site makes or may make available to Users means of link (such as, among others, links, banners, buttons), directories and search engines that allow Users to access websites owned and / or managed by third parties.</p>
            <p>The installation of these links, directories and search engines on the Web Site is intended to facilitate the Users the search for and access to information available on the Internet, without being considered a suggestion, recommendation or invitation to visit them.</p>
            <p>Miquel Trallero does not offer or market by itself or through third parties the products and/or services available in such linked sites.</p>
            <p>Miquel Trallero offers sponsored content, advertisements and/or affiliate links. The information that appears in these affiliate links or the advertisements inserted, are provided by the advertisers themselves, so Miquel Trallero is not responsible for any inaccuracies or errors that may contain the ads, nor does it guarantee in any way the experience, integrity or responsibility of the advertisers or the quality of their products and / or services.</p>
            <p>Likewise, it does not guarantee the technical availability, accuracy, veracity, validity or legality of sites outside its property that can be accessed through the links.</p>
            <p>Miquel Trallero shall in no case review or control the content of other websites, nor approve, examine or endorse the products and services, contents, files and any other material existing in the aforementioned linked sites.</p>
            <p>Miquel Trallero assumes no liability for any damages that may arise from the access, use, quality or legality of the contents, communications, opinions, products and services of websites not managed by Miquel Trallero and which are linked to this Web Site.</p>
            <p>The User or third party who makes a hyperlink from a web page of another, different, web site to the Miquel Trallero Web Site must know that:</p>
            <ul>
              <li>The reproduction - in whole or in part - of any of the Contents and/or Services of the Website is not permitted without express authorization from Miquel Trallero.</li>
              <li>No false, inaccurate or incorrect statement about the Miquel Trallero Web Site, nor about the Contents and/or Services of the same is permitted.</li>
              <li>With the exception of the hyperlink, the web site in which the hyperlink is established shall not contain any element of this Web Site protected as intellectual property by the Spanish legal system, unless expressly authorized by Miquel Trallero.</li>
              <li>The establishment of the hyperlink shall not imply the existence of a relationship between Miquel Trallero and the owner of the website from which it is made, nor the knowledge and acceptance by Miquel Trallero of the contents, services and/or activities offered in said website, and vice versa.</li>
            </ul>

            <h2>V. INTELLECTUAL AND INDUSTRIAL PROPERTY</h2>

            <p>Miquel Trallero by itself or as an assignee, owns all intellectual and industrial property rights of the Website, as well as the elements contained therein (including but not limited to images, sound, audio, video, software or text, trademarks or logos, color combinations, structure and design, selection of materials used, computer programs necessary for its operation, access and use, etc.). They are, therefore, works protected as intellectual property by the Spanish legal system, being applicable to them both the Spanish and Community regulations in this field, as well as the international treaties related to the matter and signed by Spain.</p>
            <p>All rights reserved. By virtue of the provisions of the Intellectual Property Law, the reproduction, distribution and public communication, including its mode of making available, of all or part of the contents of this website, for commercial purposes, in any medium and by any technical means, without the authorization of Miquel Trallero, are expressly prohibited.</p>
            <p>The User undertakes to respect the intellectual and industrial property rights of Miquel Trallero. You may view the elements of the Web Site or even print, copy and store them on the hard drive of your computer or any other physical medium as long as it is exclusively for your personal use. The User, however, may not remove, alter or manipulate any protection device or security system installed on the Website.</p>
            <p>In the event that the User or third party considers that any of the Contents of the Web Site involves a violation of the rights of protection of intellectual property, he/she must immediately notify Miquel Trallero through the contact details in the GENERAL INFORMATION section of this Legal Notice and General Conditions of Use.</p>

            <h2>VI. LEGAL ACTIONS, APPLICABLE LAW AND JURISDICTION</h2>

            <p>Miquel Trallero reserves the right to file the civil or criminal actions it deems necessary for the improper use of the Web Site and Contents, or for the breach of these Conditions.</p>
            <p>The relationship between the User and Miquel Trallero shall be governed by the regulations in force and applicable in Spanish territory. Should any controversy arise in relation to the interpretation and/or application of these Conditions, the parties will submit their conflicts to the ordinary jurisdiction, submitting themselves to the judges and courts that correspond according to law.</p>
            <p>This document of Legal Notice and General Conditions of use of the web site has been created by means of the generator of template of legal notice and conditions of use online on 16/07/2021.</p>

          </div>
        </div>        
        
      </Layout>
    );
  }
}